.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards; // For keeping animation on page
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left; //
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    // Change color here later on probably
    input[type="text"],
    input[type="email"] {
        width: 100%;
        border: 0;
        background: #b4b4b4;
        height: 50px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #b4b4b4;
        height: 50px;
        font-size: 16px;
        color: #ffffff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: #ffd700;
        background: 0 0;
        font-size: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #ffd700;
        float: right;
        border-radius: 4px;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }
}